import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Snatch 5-5-5-5\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`15 Power Snatch (115/75)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Monday we will have our free Labor Day workout at 10:30am.
 Everyone is invited so bring a friend!  All other classes and open gym
are cancelled for the day.   After the class all members and their
families are invited to the Barnetts house to swim, cookout and relax!
 Email, call or text for directions or more info.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 6 is coming September 15th.  We will accept late
registrations until Sept 8th but you will not get your shirts on the
15th.  If you’d like to compete but don’t have a team we have a signup
sheet by the white board. You can also email us to get on a team. If
you’d like to help judge please add your name to the sheet.  We still
need several more judges.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      